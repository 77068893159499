<template>
  <div>
    <v-row
      class="pa-3"
    >
      <v-col
        cols="12"
        md="4"
        order="2"
        order-md="1"
      >
        <v-expansion-panels
          v-model="panels"
          accordion
          multiple
        >
          <ocorrencia-veiculos
            :ocorrencia-id="Number.parseInt(ocorrencia.id || 0)"
            :seeOnly="true"
          />
          <ocorrencia-pessoas
            :ocorrencia-id="Number.parseInt(ocorrencia.id || 0)"
            :seeOnly="true"
          />
          <ocorrencia-ocorrencias-vinculadas
            :ocorrencia-id="Number.parseInt(ocorrencia.id || 0)"
            :seeOnly="true"
          />
          <ocorrencia-anexos
            :ocorrencia-id="Number.parseInt(ocorrencia.id || 0)"
            :seeOnly="true"
          />
        </v-expansion-panels>
      </v-col>
      
      <v-col
        cols="12"
        md="8"
        order="1"
        order-md="2"
      >
        <validation-observer ref="observer">
          <v-row class="pt-3">
            <v-col
              cols="8"
            >
              <v-menu
                v-model="menuData"
                :nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                disabled
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="data_ocorrencia" 
                    name="Data"
                  >
                    <v-text-field
                      v-model="ocorrenciaNova.data"
                      v-mask="'##/##/####'"
                      label="Data"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      outlined
                      append-icon="mdi-calendar"
                      disabled
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="ocorrenciaNova.data"
                  no-title
                  class="ma-0"
                  @input="menuData = false"
                  disabled
                />
              </v-menu>
            </v-col>
            <v-col
              cols="4"
              class="pl-0"
            >
              <v-menu
                ref="menu"
                v-model="menuHora"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="ocorrenciaNova.hora"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                disabled
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="ocorrencia.data_ocorrencia.split(' ')[1]"
                    v-mask="'##:##'"
                    label="Hora"
                    outlined
                    dense
                    hide-details="auto"
                    append-icon="mdi-clock"
                    @click:append="menuHora = true"
                    disabled
                  />
                </template>
                <v-time-picker
                  v-model="ocorrencia.data_ocorrencia.split(' ')[1]"
                  class="ma-0"
                  no-title
                  format="24hr"
                  disabled
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="natureza_ocorrencia_id"
              >
                <v-select
                  v-model="ocorrenciaNova.natureza_ocorrencia_id"
                  :items="naturezasOcorrencia"
                  item-text="descricao"
                  item-value="id"
                  outlined
                  dense
                  disabled
                  label="Natureza"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="tipo_ocorrencia_id"
              >
                <v-select
                  v-model="ocorrenciaNova.tipo_ocorrencia_id"
                  :items="tiposOcorrencia"
                  item-text="descricao"
                  item-value="id"
                  outlined
                  dense
                  label="Tipo"
                  disabled
                  hide-details="auto"
                  :loading="carregandoBuscarTipos"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="6"
              class="pl-0"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="situacao_ocorrencia_id"
              >
                <v-select
                  v-model="ocorrenciaNova.situacao_ocorrencia_id"
                  :items="situacoesOcorrencia"
                  item-text="descricao"
                  item-value="id"
                  outlined
                  dense
                  label="Situação"
                  disabled
                  hide-details="auto"
                  :loading="carregandoBuscarSituacoes"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="descricao"
              >
                <v-textarea
                  v-model="ocorrenciaNova.descricao"
                  outlined
                  label="Descrição"
                  hide-details="auto"
                  dense
                  disabled
                  :value="ocorrenciaNova.descricao"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="9"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="logradouro"
              >
                <v-text-field
                  v-model="ocorrenciaNova.logradouro"
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                  label="Logradouro"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="4"
              md="3"
              class="pl-md-0"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="numero"
              >
                <v-text-field
                  v-model="ocorrenciaNova.numero"
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                  label="Número"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="8"
              md="5"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="bairro"
              >
                <v-text-field
                  v-model="ocorrenciaNova.bairro"
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                  label="Bairro"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="7"
              md="4"
              class="pl-md-0"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="complemento"
              >
                <v-text-field
                  v-model="ocorrenciaNova.complemento"
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                  label="Complemento"
                  :error-messages="errors"
    
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="5"
              md="3"
              class="pl-md-0"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="cep"
              >
                <v-text-field
                  v-model="ocorrenciaNova.cep"
                  v-mask="'#####-###'"
                  outlined
                  dense
                  disabled
                  hide-details="auto"
                  label="CEP"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="6"
              md="5"
            >
              <autocomplete-estados
                v-model="estadoId"
                disabled
              />
            </v-col>
            <v-col
              cols="6"
              md="4"
              class="pl-md-0"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="cidade_id"
              >
                <autocomplete-cidades
                  v-model="ocorrenciaNova.cidade_id"
                  :estado-id="estadoId"
                  :error-messages="errors"
                  disabled
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-col>
    </v-row>
    <v-divider class="mt-4" />
    <v-row>
      <v-col
        cols="12"
        class="pa-7 text-left"
      >
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import naturezasApi from '@/api/naturezas-ocorrencia'
  import tiposApi from '@/api/tipos-ocorrencia'
  import situacoesApi from '@/api/situacoes-ocorrencia'
  import dayjs from 'dayjs'
import { getDefaultWatermarks } from 'istanbul-lib-report'

  export default {
    components: {
      AutocompleteEstados: () => import('@/components/input/InputAutocompleteEstados'),
      AutocompleteCidades: () => import('@/components/input/InputAutocompleteCidades'),
      OcorrenciaVeiculos: () => import('@/views/pages/ocorrencias/tabs/ocorrencia/OcorrenciasTabsOcorrenciaVeiculos'),
      OcorrenciaAnexos: () => import('@/views/pages/ocorrencias/tabs/ocorrencia/OcorrenciasTabsOcorrenciaAnexos'),
      OcorrenciaPessoas: () => import('@/views/pages/ocorrencias/tabs/ocorrencia/OcorrenciasTabsOcorrenciaPessoas'),
      OcorrenciaOcorrenciasVinculadas: () => import('@/views/pages/ocorrencias/tabs/ocorrencia/OcorrenciasTabsOcorrenciaOcorrenciasVinculadas'),
    
    },

    props: {
      validacaoFormulario: {
        type: Object,
        default: () => { return {} },
      },
      carregandoSalvar: {
        type: Boolean,
        default: false,
      },
      carregandoBuscar: {
        type: Boolean,
        default: false,
      },
      ocorrencia: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        editar: false,
        panels: [0, 1],
        menuData: false,
        menuHora: false,
        dataFormatada: dayjs().format('DD/MM/YYYY'),
        estadoId: null,
        carregandoBuscarNaturezas: false,
        carregandoBuscarTipos: false,
        carregandoBuscarSituacoes: false,
        naturezasOcorrencia: [],
        tiposOcorrencia: [],
        situacoesOcorrencia: [],
        ocorrenciaNova: {
          data: dayjs().format('YYYY-MM-DD'),
          hora: dayjs().format('HH:mm'),
        },
      }
    },

    watch: {
      validacaoFormulario (erros) {
        if (!erros) return

        this.$refs.observer.reset()
        this.$refs.observer.setErrors(erros)
      },

      ocorrencia: {
        immediate: true,
        handler (ocorrencia) {
          if (!ocorrencia || !ocorrencia.id) return


          console.log(ocorrencia.data_ocorrencia);
          
          ocorrencia = {
            ...ocorrencia.endereco,
            ...ocorrencia,
            data: ocorrencia.data_ocorrencia.split(' ')[0].substring(8,10)+"/"
                  +ocorrencia.data_ocorrencia.split(' ')[0].substring(5,7)+
                  '/'+ocorrencia.data_ocorrencia.split(' ')[0].substring(0,4),
            hora: ocorrencia.data_ocorrencia.split(' ')[1].substring(0, 5),
          }

          this.ocorrenciaNova = ocorrencia
          if (ocorrencia.endereco) {
            this.estadoId = ocorrencia.endereco.cidade.estado_id
          }

          this.editar = true
        },
      },

      'ocorrenciaNova.data' (data) {
        this.dataFormatada = this.formatarData(data)
      },

    },

    mounted () {
      this.buscarNaturezas()
      this.buscarTipos()
      this.buscarSituacoes()
    },

    methods: {
      async buscarNaturezas () {
        try {
          this.carregandoBuscarNaturezas = true
          const resposta = await naturezasApi.listar({ por_pagina: 1000, pagina: 1 })
          this.naturezasOcorrencia = resposta.data.naturezas_ocorrencia
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoBuscarNaturezas = false
        }
      },

      async buscarTipos () {
        try {
          this.carregandoBuscarTipos = true
          const resposta = await tiposApi.listar({ por_pagina: 1000, pagina: 1 })
          this.tiposOcorrencia = resposta.data.tipos_ocorrencia
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoBuscarTipos = false
        }
      },

      async buscarSituacoes () {
        try {
          this.carregandoBuscarSituacoes = true
          const resposta = await situacoesApi.listar({ por_pagina: 1000, pagina: 1 })
          this.situacoesOcorrencia = resposta.data.situacoes_ocorrencia
        } catch (e) {
          console.log(e)
        } finally {
          this.carregandoBuscarSituacoes = false
        }
      },

      formatarData (data) {
        if (!data) return null

        const [ano, mes, dia] = data.split('-')
        return `${dia}/${mes}/${ano}`
      },

      formatarDataApi (data) {
        if (!data) return null

        const [dia, mes, ano] = data.split('/')
        return `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`
      },
    },
  }
</script>
